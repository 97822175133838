



jQuery(document).ready(function($) {




    /* Konstanten
    --------------------------------------------------------------------------------------------- */

    const siteBody                      = $('body');
    const menu                          = $('.menu');
    const burger                        = $('.burger');
    const burgerOpenSpan                = $('.burger .open span');
    const burgerCloseSpan               = $('.burger .close span');
    const burgerIsClose                 = '.is-close';
    const burgerIsOpen                  = '.is-open';
    const headerContainer               = $("#header");
    const mainContainer                 = $("#main");
    const video                         = $(".js-player");
    const navItem                       = $('li.nav-item');
    const navItemLink                   = $("li.nav-item.parent > a");
    const overlayDistance               = $(".overlay-distance");
    const headerVideoContainer          = $(".header-video-container");




    /* Navigation
    --------------------------------------------------------------------------------------------- */
    $(navItemLink).click(function (e) {

        $( "ul.sub" ).each(function() {
            $(this).hide();
        });

        $(navItem).each(function() {
            $(this).removeClass('click-on');
            $(this).addClass('click-off');
        });

        $(this).parent().removeClass('click-off');
        $(this).parent().addClass('click-on');
        $(this).next().show();

        e.preventDefault();

    });

    function clearNavClick() {

        // alle Subs ausblenden!
        // $( "ul.sub" ).each(function() {
        //     $(this).hide();
        // });

        // alle Klick-Classes entfernen!
        $(navItem).each(function() {
            $(this).removeClass('click-on');
            $(this).removeClass('click-off');

            // Original Sub wieder einblenden!
            if( $(this).hasClass('active') ) {
                $(this).find('.sub').show();
            } else {
                $(this).find('.sub').hide();
            }

        });
    }




    /* Timeline
    --------------------------------------------------------------------------------------------- */

    if ( $( ".timeline-container" ).length ) {

        //$(".timeline-out-button").click(function () {
        $(document).on('click', ".timeline-out-button", function () {

            const timelineItemId = "#" + $(this).data('timeline-id');

            if ($(this).hasClass("active")) {
                $(this).removeClass("active");
            } else {
                $(this).addClass("active");
            }

            if ($(timelineItemId).hasClass("active")) {
                $(timelineItemId).removeClass("active").find(".copy-container").slideUp(300);
            } else {
                $(timelineItemId).addClass("active").find(".copy-container").slideDown(300);
            }

        });


        var startCloneTimelineButton = false;

        function cloneTimelineButton() {

            if( startCloneTimelineButton === false) {

                if ($(window).width() < 768) {

                    $(".timeline-container .item").each(function () {
                        var $horst = $(this).find('.copy-container');
                        $(this).find('.timeline-out-button').clone().insertAfter($horst);
                    });

                    startCloneTimelineButton = true;
                }
            }
        }

        cloneTimelineButton();

        $(window).on("resize", function () {
            cloneTimelineButton();
        });

    }




    /* Soundcloud Unfold
    --------------------------------------------------------------------------------------------- */

    if ( $( ".unfold-container" ).length ) {

        $(document).on('click', ".unfold-container .icon", function () {

            if ($(this).hasClass("active")) {
                //$(this).removeClass("active");
                $(this).removeClass("active").parent().find('.text').slideUp(300);
            } else {
                //$(this).addClass("active");
                $(this).addClass("active").parent().find('.text').slideDown(300);
            }

        });

    }




    /* Image Hotspots
    --------------------------------------------------------------------------------------------- */

    if ( $( ".widget-image-hotspots" ).length ) {

        $(document).on('click', ".hotspot", function () {

            if ($(this).hasClass("active")) {

                //$(this).removeClass("active").find('.text').slideUp(300);
                $(this).removeClass("active").find('.text').fadeOut(300);

            } else {

                $(".hotspot").each(function () {
                    if ($(this).hasClass("active")) {
                        $(this).removeClass("active").find('.text').fadeOut(300);
                    }
                });

                $(this).addClass("active").find('.text').fadeIn(300);
            }



        });

    }




    /* Video Player Plyr @https://www.npmjs.com/package/plyr#initialising
    --------------------------------------------------------------------------------------------- */
    if( $(video).length ) {

        $(video).each(function () {
            var videoId = $(this).attr('id');
            new Plyr('#' + videoId, {

                controls: [
                    'play-large', // The large play button in the center
                    'restart', // Restart playback
                    //'rewind', // Rewind by the seek time (default 10 seconds)
                    //'play', // Play/pause playback
                    //'fast-forward', // Fast forward by the seek time (default 10 seconds)
                    'progress', // The progress bar and scrubber for playback and buffering
                    'current-time', // The current time of playback
                    //'duration', // The full duration of the media
                    'mute', // Toggle mute
                    'volume', // Volume control
                    //'captions', // Toggle captions
                    //'settings', // Settings menu
                    //'pip', // Picture-in-picture (currently Safari only)
                    //'airplay', // Airplay (currently Safari only)
                    //'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
                    'fullscreen', // Toggle fullscreen
                ]

                //hideControls: false

            });

            //const players = Plyr.setup('.js-player');

        });

        $(".plyr button").click(function() {
            //var videoContainer = $(this).parents().find(".video-container");
            var videoContainer = $(this).parents(".video-container");
            $(videoContainer).removeClass('hide-controls');
        });

    }



    /* Header Height Size
    --------------------------------------------------------------------------------------------- */
    function setBurgerContainerHeight() {

        // get Header-Container => Height
        const headerContainerHeight = $(headerContainer).height();

        // set Main-Container => Margin (top)
        $(mainContainer).css('padding-top', headerContainerHeight + "px");
        $(overlayDistance).css('padding-top', headerContainerHeight + "px");

        // set Header Video Container => Height
        if ( $(headerVideoContainer).length ) {
            $(headerVideoContainer).css('height', "calc(100vh - " + headerContainerHeight + "px)");
        }

    }

    if( $(headerContainer).length ) {

        setBurgerContainerHeight();
        $(window).on("resize", function () {
            setBurgerContainerHeight();
        });
    }



    /* Header Scroll | via headroom @https://wicky.nillia.ms/headroom.js/
    --------------------------------------------------------------------------------------------- */
    $(headerContainer).headroom({
        tolerance: {
            down: 10,
            up: 20
        },
        offset: 15
    });



    /* Scroll Top Icon | via headroom @https://wicky.nillia.ms/headroom.js/
    --------------------------------------------------------------------------------------------- */
    $(".scroll-top-arrow").click(function() {
        $('html,body').animate({scrollTop:0}, 600);
    });

    $(siteBody).headroom({
        offset: 800,
        classes : {
            notTop : "scroll-top-show"
        }
    });



    /* Accordion
    --------------------------------------------------------------------------------------------- */
    $(".accordion-container h3, .accordion-container .mobil-unfold").click(function() { //TODO: unfold

        const accordionId = "#" + $(this).data('accordion-id');

        if( $(this).hasClass("level-1") ) {

            const accordionContent = $(this).parent().find(".accordion-content.level-1");

            if ($(accordionContent).hasClass("active")) {

                $(accordionContent).prev().removeClass("active");
                $(accordionContent).removeClass("active").slideUp(350);
                $(accordionContent).next().removeClass("active"); //TODO: unfold

            } else {

                $(accordionId + " .accordion-content.level-1").each(function () {

                    if ($(this).hasClass("active")) {

                        $(this).prev().removeClass("active");
                        $(this).removeClass("active").slideUp(350);
                        $(this).next().removeClass("active");
                        //alert('close open');
                    }
                });

                $(accordionContent).prev().addClass("active");
                $(accordionContent).addClass("active").slideDown(350);
                $(accordionContent).next().addClass("active"); //TODO: unfold
            }
        }
    });





    /* Gallery Modal
    --------------------------------------------------------------------------------------------- */
    $(".show-gallery-modal").click(function() {

        const galleryModalId = "#" + $(this).parent().parent().data('gallery-id');
        const galleryModalNumber = $(this).parent().data('img-number');
        //alert(galleryModalNumber);

        $(siteBody).append('<div id="gallery-modal">\n' +
            '                    <section class="swiper-container slider-gallery-modal">\n' +
            '                        <div class="swiper-wrapper"></div>\n' +
            '                        <div class="swiper-pagination"></div>\n' +
            '                        <div class="swiper-button-prev">\n' +
            '                            <svg class="circle-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49">\n' +
            '                              <circle class="circle" cx="24.5" cy="24.5" r="24.5"/>\n' +
            '                              <path class="arrow" d="M28.87,34.43l-9.93-9.93,9.93-9.93"/>\n' +
            '                            </svg>\n' +
            '                        </div>\n' +
            '                        <div class="swiper-button-next">\n' +
            '                            <svg class="circle-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49">\n' +
            '                                <circle class="circle" cx="24.5" cy="24.5" r="24.5"/>\n' +
            '                                <path class="arrow" d="M20.13,14.57l9.93,9.93-9.93,9.93"/>\n' +
            '                            </svg>\n' +
            '                        </div>\n' +
            '                        <div class="swiper-button-close">\n' +
            '                            <svg class="close-icon" xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49">\n' +
            '                                <circle class="circle-close" cx="24.5" cy="24.5" r="24.5"/>\n' +
            '                                <g>\n' +
            '                                    <path class="circle-close-path" d="M14.57,14.57l9.93,9.93-9.93,9.93"/>\n' +
            '                                    <path class="circle-close-path" d="M34.43,34.43l-9.93-9.93,9.93-9.93"/>\n' +
            '                                </g>\n' +
            '                            </svg>\n' +
            '                        </div>\n' +
            '                    </section>\n' +
            '                </div>');

        $(galleryModalId + " .gallery-item").each(function () {

            //alert('lo');
            var imgUrl = $(this).data('img-url');
            $('#gallery-modal .swiper-wrapper').append('<div class="swiper-slide"><img src="' + imgUrl + '" alt="" title=""></div>');

        });

        initSliderGalleryModal(galleryModalNumber);

    });

    $(document).on('click', '.swiper-button-close', function() {
        $('#gallery-modal').fadeOut( 500, function () {
            $('#gallery-modal').remove();
        });
    });




    /* Swiper | Slider Gallery Modal
    --------------------------------------------------------------------------------------------- */

    const sliderGalleryModalSelector = '.slider-gallery-modal',

        optionsSliderGalleryModal = {

            loop: true,
            grabCursor: true,
            autoHeight: false,
            slidesPerView: 1,

            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },

            keyboard: {
                enabled: true,
            }
        };

    function initSliderGalleryModal(galleryModalNumber) {
        const sliderGalleryModal = new Swiper(sliderGalleryModalSelector, optionsSliderGalleryModal);
        //sliderGalleryModal.slideTo(galleryModalNumber+1, 0, false); // TODO: index, speed, runCallbacks
        sliderGalleryModal.slideToLoop(galleryModalNumber, 0, false); // TODO: index, speed, runCallbacks
        $('#gallery-modal').fadeTo(500, 1);
    }



    /* Swiper | Slider Two Image Text
    --------------------------------------------------------------------------------------------- */
    const sliderOneImageTextSelector = '.slider-one-image-text',

        optionsSliderOneImageText = {

            //init: false,
            loop: true,
            //loopedSlides: 8,
            //draggable: true,
            //centeredSlides: true,
            grabCursor: true,
            autoHeight: true,


            // slidesPerView: 'auto',
            // spaceBetween: 20,
            // freeMode: true,

            slidesPerView: 1,

            //speed: 1000,

            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },

            on: {
                imagesReady: function () {
                    this.el.classList.remove('loading');
                }
            }
        };

    function initSliderOneImageText() {
        const sliderOneImageText = new Swiper(sliderOneImageTextSelector, optionsSliderOneImageText);
    }

    var sliderOneImageTextContainer = $(".slider-one-image-text");

    if( sliderOneImageTextContainer.length ) {
        console.log('initSliderOneImageText');
        initSliderOneImageText();
    }




    /* Swiper | Slider Two Image Text
    --------------------------------------------------------------------------------------------- */
    const sliderTwoImageTextSelector = '.slider-two-image-text',

        optionsSliderTwoImageText = {

            //init: false,
            loop: true,
            //loopedSlides: 8,
            //draggable: true,
            //centeredSlides: true,
            grabCursor: true,
            autoHeight: true,


            // slidesPerView: 'auto',
            // spaceBetween: 20,
            // freeMode: true,

            slidesPerView: 1,

            //speed: 1000,

            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },

            on: {
                imagesReady: function () {
                    this.el.classList.remove('loading');
                }
            }
        };

    function initSliderTwoImageText() {
        const sliderTwoImageText = new Swiper(sliderTwoImageTextSelector, optionsSliderTwoImageText);
    }

    var sliderTwoImageTextContainer = $(".slider-two-image-text");

    if( sliderTwoImageTextContainer.length ) {
        console.log('initSliderTwoImageText');
        initSliderTwoImageText();
    }



    /* GSAP
    --------------------------------------------------------------------------------------------- */
    gsap.defaults({
        overwrite: "auto"
        //overwrite: true
    });

    gsap.config({
        //autoSleep: 60,
        //force3D: false,
        nullTargetWarn: false,
        //units: {left: "%", top: "%", rotation: "rad"}
    });

    gsap.registerPlugin(
        //CSSRulePlugin,
        //ScrollToPlugin
    );



    /* Add Mouse Stick Container
    --------------------------------------------------------------------------------------------- */
    // TODO: wird mit barba.js aufgerufen (barba.init => once)
    //  Darf nur einmal aufgerufen werden!
    // function addMouseStickContainer() {
    //     console.log('addMouseStickContainer (once)');
    //     if( ! is_touch_enabled() ) {
    //         $(siteBody).append('<div class="mouse-stick"><span class="hold-move">Hold & Move</span></div>');
    //     }
    // }

    /* Move-Circle-Effect
    --------------------------------------------------------------------------------------------- */
    /*
    function initMouseCircleEffect() {

        // Only Touch screen
        if( ! is_touch_enabled() ) {

            const mouseStick = '.mouse-stick';
            const parallaxWrapClass = '.parallax-wrap';

            function moveCircle(e) {
                gsap.to(mouseStick, {
                    duration: 2,
                    css: {
                        x: e.clientX,
                        y: e.clientY
                    }, ease: Power4.easeOut
                });
            }

            $(window).on('mousemove', moveCircle);

            // TODO: Mouseover
            $(parallaxWrapClass).mouseenter(function () {

                gsap.to($(this).children(), {duration: 0.8, scale: 1.2});

                // scale mouse-stick by mouseover
                gsap.to(mouseStick, {
                    duration: 0.8,
                    width: '60px',
                    height: '60px',
                    top: '-30px',
                    left: '-30px',
                    opacity: 1
                });
            });

            // TODO: Mouseout
            $(parallaxWrapClass).mouseleave(function () {

                gsap.to($(this).children(), {
                    duration: 0.8,
                    scale: 1,
                    x: 0,
                    y: 0
                });

                // scale mouse-stick by mouseout
                gsap.to(mouseStick, {
                    duration: 0.8,
                    //scale:1,
                    width: '30px',
                    height: '30px',
                    top: '-15px',
                    left: '-15px',
                    opacity: 1
                });
            });

            // TODO: Mousemove
            $(parallaxWrapClass).mousemove(function (e) {
                let parallaxWrap = $(this);
                let parallaxWrapInner = $(this).children();
                callParallaxNav(e, parallaxWrapInner, parallaxWrap);
            });

            function callParallaxNav(e, parallaxWrapInner, parallaxWrap) {
                parallaxItNav(e, parallaxWrapInner, 20, parallaxWrap);
            }

            function parallaxItNav(e, target, movement, parallaxWrap) {
                const $thisParallaxWrap = parallaxWrap;
                const relX = e.pageX - $thisParallaxWrap.offset().left;
                const relY = e.pageY - $thisParallaxWrap.offset().top;

                gsap.to(target, {
                    duration: 0.8,
                    x: (relX - $thisParallaxWrap.width() / 2) / $thisParallaxWrap.width() * movement,
                    y: (relY - $thisParallaxWrap.height() / 2) / $thisParallaxWrap.height() * movement,
                    ease: Power2.easeOut
                });
            }
        }
    }

    initMouseCircleEffect();

    */







    gsap.set('.burger .close span', {scaleX: 0, force3D: !0});

    $(document).on('click', burgerIsClose, function (e) {
        //e.preventDefault();
        menuAnimationCloseToOpen();
    });

    function menuAnimationCloseToOpen() {

        //console.log('menu Open');
        const tl = gsap.timeline({
            defaults: {
                duration: 0.8,
                ease: 'power1.inOut'
            },
            onComplete: function () {
                // TODO: wird nicht gebraucht, da das Menü nach oben schließt!
                //gsap.set(menu, { bottom: 0, top: 'auto' });
                gsap.set(burger, { className: "burger is-open"});
            }
        });

        tl
            // .menu => menu
            .set(burger, { className: "burger get-open"})
            .to(menu, { height: '100%' })
            //.to(main, { y: 150 }, '-=0.8')

            .fromTo(burgerOpenSpan, {scaleX: 1, force3D: !0}, {duration: 0.3, scaleX: 0, force3D: !0, stagger: 0.05}, '-=0.8' )
            .fromTo(burgerCloseSpan, {scaleX: 0, force3D: !0}, {duration: 0.3, scaleX: 1, force3D: !0, stagger: 0.05}, '-=0.3' )
        ;

        //return tl;
    }


    //$(burgerIsOpen).click(function (e) {
    $(document).on('click', burgerIsOpen, function (e) {
        //e.preventDefault();
        menuAnimationOpenToClose();
    });

    function menuAnimationOpenToClose() {

        //console.log('menu Close');
        const tl = gsap.timeline({
            defaults: {
                duration: 0.8,
                ease: 'power1.inOut'
            },
            onComplete: function () {
                // TODO: wird nicht gebraucht, da das Menü nach oben schließt!
                //gsap.set(menu, { top: 0, bottom: 'auto' });
                gsap.set(burger, { className: "burger is-close"});
            }
        });

        tl
            // .menu => menu
            .set(burger, { className: "burger get-close"})
            .to(menu, { height: 0 })
            //.fromTo(main, { y: -150 }, {y: 0 }, '-=0.8')

            .fromTo(burgerCloseSpan, {scaleX: 1, force3D: !0}, {scaleX: 0, force3D: !0, stagger: 0.05}, '-=1.0' )
            .fromTo(burgerOpenSpan, {scaleX: 0, force3D: !0}, {scaleX: 1, force3D: !0, stagger: 0.05}, '-=0.5' )
        ;

    }



});